import { defineMessages } from "react-intl";

export const scope = "pages.OrganizationInviteRedirectPage";

export const messages = defineMessages({
  title: {
    id: `${scope}.title`,
    defaultMessage: "Continuá desde tu celular",
  },
  subtitle: {
    id: `${scope}.subtitle`,
    defaultMessage: "Te pedimos que abras el mismo link desde tu celular para continuar.",
  },
});
