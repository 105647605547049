import { FormattedMessage } from "react-intl";
import { messages } from "./messages";
import { ReactComponent as SuccessImage } from "assets/images/phone-with-success-icon.svg";
import { useQueryParams } from "hooks/utils/useQueryParams";
import { useEffect } from "react";
import { isMobile } from "react-device-detect";

export const OrganizationInviteRedirectPage: React.FC = () => {
  const params = useQueryParams();

  useEffect(() => {
    const token = params.get("token");

    if (token && isMobile) {
      window.open(`com.letsbit.app://?token=${token}`, "_self");
    }
  }, [params]);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center p-8">
      <SuccessImage />
      <h1 className="mb-8 mt-8 w-full text-center font-['Tomato_Grotesk'] text-4xl font-bold text-[--milkylb-color-secondary-600]">
        <FormattedMessage {...messages.title} values={{ br: <br /> }} />
      </h1>

      <h3 className="w-full text-center text-xl text-[--milkylb-color-primary-800]">
        <FormattedMessage {...messages.subtitle} values={{ br: <br /> }} />
      </h3>
    </div>
  );
};
