import {
  UserWithFullInfo,
  LoginForm,
  USER_STATE,
  PERSON_TYPES,
  FLOW_NEXT_STEP,
  EVENT_TRACKER_EVENT_NAMES,
  LoginMethods,
  UserWithSessionInfo,
  UserAccess,
  PERMISSION_STATE,
} from "@letsbit/malcolmlb";
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useIntercom } from "react-use-intercom";

import { createVoidContext } from "utils/voidContext";
import { ApiContext } from "./ApiProvider";
import { useQueryParams } from "hooks/utils/useQueryParams";
import { useLocalStorage } from "hooks/utils/useLocalStorage";
import { DefaultError, errorParser, onErrorHandler } from "utils/defaultError";
import { LocaleContext } from "./LocaleProvider";
// import { useError } from "hooks/utils/useError";
import { useNotification } from "hooks/utils/useNotification";
import { NotificationStatus } from "components/Notification/types";
import { useEventTracking } from "hooks/event_tracking/useEventTracking";
import { CSRFContext } from "./CSRFProvider";

export interface UserData {
  country_residence?: string;
  preferred_currency?: string;
  utm_source?: string | null;
  utm_medium?: string | null;
  utm_campaign?: string | null;
  referrer?: string;
  hdyhau?: string;
  person_type?: PERSON_TYPES;
  investment_notifications?: Record<string, string>;
}

export interface UTMParams {
  utm_source: string | null;
  utm_medium: string | null;
  utm_campaign: string | null;
}

export enum USER_ACCOUNT_STATUS {
  PENDING = "pending",
  ACTIVE = "active",
  BANNED = "banned",
}

export type User = Omit<UserWithFullInfo, "data"> & {
  data: UserData;
  otpAvailable: boolean;
  enrollRequired: boolean;
  permission: PERMISSION_STATE;
  using: {
    email: string;
    level: number;
    role: string;
    uid: string;
  };
};

type UserContext = {
  user?: User | null;
  auth0LogIn: (params: {
    captcha_response: string;
    redirectPath: string;
    onErrorCallback?: ((error: unknown) => void) | undefined;
    signUpRedirect?: string | undefined;
  }) => Promise<void>;
  UTMParams: UTMParams;
  getUser: (redirectPath?: string) => Promise<void>;
  getUserWithoutRedirect: () => Promise<void>;
  loading: boolean;
  logout: (redirectPath?: string) => Promise<void>;
  login: (form: LoginForm, redirectPath: string) => Promise<void>;
  auth0isAuthenticated: boolean;
  updateUserData: (userData: UserData) => Promise<void>;
  checkSession: (onFinished?: () => void) => Promise<void>;
  enterpriseLogin: (params: {
    redirectPath: string;
    captcha_response: string;
  }) => Promise<void>;
};

export const UserContext = createContext<UserContext>(
  createVoidContext("user"),
);

export const UserProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const { shutdown } = useIntercom();
  const { publicInstance, memberInstance } = useContext(ApiContext);
  const { setCSRFToken } = useContext(CSRFContext);
  const eventTracker = useEventTracking();
  // const { addError } = useError();
  const { showNotification } = useNotification();

  const [user, setUser] = useState<User | undefined | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const { restoreIPValidLocale } = useContext(LocaleContext);

  const { clear: clearLocalStorage } = useLocalStorage();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectPathRef = useRef<string>(
    `${location.pathname}${location.search}`,
  );

  const query = useQueryParams();
  const UTMParams = useMemo(
    () => ({
      utm_source: query.get("utm_source"),
      utm_medium: query.get("utm_medium"),
      utm_campaign: query.get("utm_campaign"),
    }),
    [query],
  );

  // useEffect(() => {
  //   const isUserPending = userState === "pending";
  //   if (uid && !isUserPending) {
  //     navigate(redirectPathRef.current);
  //   }
  // }, [uid, userState]);

  const updateUserData = useCallback(
    async (data: UserData) => {
      try {
        const newData = !user?.data ? data : Object.assign(user?.data, data);

        const [{ data: userUpdated }, { data: userInfo }] = await Promise.all([
          memberInstance.barong.updateUserData(
            JSON.stringify(newData).replace(/</g, "\\u003c"),
          ),
          memberInstance.hodor.getMe(),
        ]);

        setUser(parseUser(userUpdated, userInfo));
      } catch (error) {
        console.error(error);
      }
    },
    [memberInstance, user],
  );

  const logout = useCallback(
    async (redirectPath = "/login") => {
      try {
        await memberInstance.hodor.logout(true);

        setUser(null);
        sessionStorage.clear();
        shutdown();
        clearLocalStorage();
        navigate(redirectPath, { replace: true });

        restoreIPValidLocale();
      } catch (error) {
        console.error(error);
        // addError(
        //   "Oups! Algo salió mal, intenta de nuevo mas tarde",
        //   "FAILED_USER_LOGOUT"
        // );
      }
    },
    [
      memberInstance.hodor,
      shutdown,
      clearLocalStorage,
      navigate,
      restoreIPValidLocale,
    ],
  );

  const parseUser = (
    userResponse: UserWithFullInfo,
    userSession: UserWithSessionInfo,
  ): User => {
    const parsedData: UserData | null = JSON.parse(userResponse.data);
    const defaultedData = {
      ...parsedData,
      country_residence:
        parsedData && "country_residence" in parsedData
          ? parsedData.country_residence
          : "",
      preferred_currency:
        parsedData && "preferred_currency" in parsedData
          ? parsedData.preferred_currency
          : "pax",
    };

    return {
      ...userResponse,
      data: defaultedData,
      otpAvailable: userSession.otp_available,
      enrollRequired:
        !userSession.otp_available && userSession.session.require_enroll,
      permission: userSession.permission,
      using: userSession.using,
    };
  };

  const redirectToOnboarding = useCallback(() => {
    navigate("/logged/onboarding");
  }, [navigate]);

  const onSuccessAuth = useCallback(
    async (
      user: UserWithFullInfo,
      userInfoSession: UserWithSessionInfo,
      redirectPath?: string,
    ) => {
      if (user.state === USER_STATE.PENDING) {
        // addError({
        //   title: "Tu cuenta no está activada",
        //   message: "Te enviaremos un mail para que la actives.",
        //   secondaryButton: "CERRAR",
        // });
        showNotification({
          title: "Tu cuenta no está activada",
          message: "Te enviaremos un mail para que la actives.",
          status: NotificationStatus.WARNING,
        });

        logout(`/signup/success?email=${encodeURIComponent(user.email)}`);
      } else if (user.state === USER_STATE.BANNED) {
        // addError({
        //   title: "Tu cuenta está baneada",
        //   message: "Contactanos al chat",
        //   secondaryButton: "CERRAR",
        // });
        showNotification({
          title: "Tu cuenta está baneada",
          message: "Contactanos al chat",
          status: NotificationStatus.WARNING,
        });

        logout();
      } else if (user.state === USER_STATE.LOCKED) {
        navigate("/login/locked");
      } else if (
        user.state === USER_STATE.RESTRICTED ||
        user.state === USER_STATE.FROZEN ||
        user.state === USER_STATE.RETRIEVED
      ) {
        navigate("/login/revision");
      } else {
        const parsedUser = parseUser(user, userInfoSession);
        setUser(parsedUser);

        if (user.level < 3) {
          redirectToOnboarding();
        } else {
          if (parsedUser.data.person_type === PERSON_TYPES.JURIDICAL) {
            navigate(redirectPath || redirectPathRef.current, {
              replace: true,
            });
          } else {
            if (!parsedUser.data.person_type) {
              const { data: customer } =
                await memberInstance.compliance.getCustomer();
              await updateUserData({
                ...parsedUser.data,
                person_type: customer.person_type,
              });
            }
            const { data: flow } =
              await memberInstance.compliance.getFlowNext();

            if (flow.next_step !== FLOW_NEXT_STEP.COMPLETED) {
              redirectToOnboarding();
            } else {
              if (parsedUser.enrollRequired) {
                navigate("/login/enroll", {
                  replace: true,
                });
                return;
              }

              navigate(redirectPath || redirectPathRef.current, {
                replace: true,
              });
            }
          }
        }
      }
    },
    [
      logout,
      memberInstance.compliance,
      navigate,
      redirectToOnboarding,
      showNotification,
      updateUserData,
    ],
  );

  const getUser = useCallback(
    async (redirectPath?: string) => {
      setLoading(true);
      try {
        const [{ data: user }, { data }] = await Promise.all([
          memberInstance.barong.getUser(),
          memberInstance.hodor.getMe(),
        ]);

        await onSuccessAuth(user, data, redirectPath);

        // TODO: Check if we stil want to use Sentry as a logging provider for frontend. Check options
        // SentrySetUser({ id: userResponse.uid });
      } catch (error) {
        switch (errorParser(error)) {
          case "authz.pending_authorization":
            navigate("/login/validate-session");
            break;
          case "authz.rejected_session":
            navigate("/login/unauthorized");
            break;
          default:
            navigate("/login");
            break;
        }

        setUser(null);
        console.error(error);
      }
      setLoading(false);
    },
    [memberInstance.barong, memberInstance.hodor, navigate, onSuccessAuth],
  );

  const getUserWithoutRedirect = useCallback(async () => {
    setLoading(true);
    try {
      const [{ data: user }, { data }] = await Promise.all([
        memberInstance.barong.getUser(),
        memberInstance.hodor.getMe(),
      ]);

      const parsedUser = parseUser(user, data);
      setUser(parsedUser);

      // TODO: Check if we stil want to use Sentry as a logging provider for frontend. Check options
      // SentrySetUser({ id: userResponse.uid });
    } catch (error) {
      setUser(null);
      console.error(error);
    }
    setLoading(false);
  }, [memberInstance.barong, memberInstance.hodor]);

  const login = useCallback(
    async (form: LoginForm, redirectPath: string): Promise<void> => {
      const onSuccess = async (
        user: UserWithFullInfo,
        userSessionInfo: UserWithSessionInfo,
        accounts: UserAccess[],
      ): Promise<void> => {
        await onSuccessAuth(
          user,
          userSessionInfo,
          accounts.length > 1 ? "/logged/select-account" : redirectPath,
        );

        eventTracker.identify(user.uid, { email: user.email });
        eventTracker.capture(EVENT_TRACKER_EVENT_NAMES.USER_LOGGED_IN, {
          method: "password",
        });

        // SentrySetUser({ id: user.uid });

        // TODO: This probably will blowout with the integration of mixpanel
        // TagManager.dataLayer({ dataLayer: { event: "user_logged" } });
        // TagManager.dataLayer({
        //   dataLayer: { event: "userIdSet", userId: user.uid },
        // });
      };

      const onError = onErrorHandler((message) => {
        switch (message) {
          case "identity.invalid_credentials":
          case "identity.session.invalid_params":
          case "identity.session.invalid_login_params":
            showNotification({
              title: "Datos incorrectos",
              message: "Mail y/o contraseña incorrectos.",
              status: NotificationStatus.ERROR,
            });
            // addError({
            //   title: "Datos incorrectos",
            //   message: "Mail y/o contraseña incorrectos.",
            //   primaryButton: "CERRAR",
            // });
            break;
          case "identity.organization_login_not_allowed":
            navigate("/login/invalid-juridical-account", { replace: true });
            break;
          case "identity.session.not_active":
            // addError({
            //   title: "Tu cuenta no está activada",
            //   message: "Contactate con soporte para resolver el problema",
            // });
            break;
          case "identity.session.invalid_otp":
            showNotification({
              title: "Datos incorrectos",
              message: "Codigo 2FA incorrecto.",
              status: NotificationStatus.ERROR,
            });
            // addError({
            //   title: "Datos incorrectos",
            //   message: "Codigo 2FA incorrecto.",
            //   primaryButton: "CERRAR",
            // });
            break;
          case "server.internal_error":
            // addError({
            //   message: "Nuestra página se encuentra en mantenimiento.",
            // });
            break;
          case "identity.session.banned":
            // addError({
            //   message:
            //     "La cuenta con la que intentas ingresar se encuentra suspendida. Ante cualquier consulta, no dudes en escribirnos al soporte.",
            // });
            navigate("/login/banned");
            break;
          case "authz.pending_authorization":
            navigate("/login/validate-session");
            break;
          case "identity.session.locked":
            // addError({
            //   message:
            //     "La cuenta con la que intentas ingresar se encuentra suspendida. Ante cualquier consulta, no dudes en escribirnos al soporte.",
            // });
            navigate("/login/locked");
            break;
          case "identity.session.restricted":
          case "identity.session.frozen":
          case "identity.session.retrieved":
            // addError({
            //   message:
            //     "La cuenta con la que intentas ingresar se encuentra suspendida. Ante cualquier consulta, no dudes en escribirnos al soporte.",
            // });
            navigate("/login/revision");
            break;
          default:
            // addError();
            break;
        }
      });

      try {
        setUser(null);
        setLoading(true);

        const { data: flow } = await publicInstance.hodor.loginBrowserBegin({
          method: LoginMethods.PASSWORD,
          captcha_response: form.captcha_response,
        });

        const { data: session } = await publicInstance.hodor.loginFinish(
          flow.id,
          {
            email: form.email,
            password: form.password,
          },
        );

        if (session.csrf_token) {
          setCSRFToken(session.csrf_token);
        }

        const [{ data: user }, { data }, { data: accounts }] =
          await Promise.all([
            memberInstance.barong.getUser(),
            memberInstance.hodor.getMe(),
            memberInstance.hodor.getAccess(),
          ]);

        await onSuccess(user, data, accounts);
      } catch (error: unknown) {
        onError(error);
      } finally {
        setLoading(false);
      }
    },
    [
      eventTracker,
      memberInstance.barong,
      memberInstance.hodor,
      navigate,
      onSuccessAuth,
      publicInstance.hodor,
      setCSRFToken,
      showNotification,
    ],
  );

  const auth0LogIn = useCallback(
    async (params: {
      redirectPath: string;
      captcha_response: string;
      onErrorCallback?: (error: unknown) => void;
      signUpRedirect?: string;
    }): Promise<void> => {
      const {
        captcha_response,
        redirectPath,
        onErrorCallback,
        signUpRedirect,
      } = params;
      const claims = await getIdTokenClaims();

      const onSuccess = async (
        user: UserWithFullInfo,
        userSessionInfo: UserWithSessionInfo,
      ): Promise<void> => {
        redirectPathRef.current = redirectPath || redirectPathRef.current;
        await onSuccessAuth(user, userSessionInfo);

        eventTracker.identify(user.uid, { email: user.email });
        eventTracker.capture(EVENT_TRACKER_EVENT_NAMES.USER_LOGGED_IN, {
          method: "auth0",
        });
      };

      const onError = onErrorCallback
        ? onErrorCallback
        : onErrorHandler((message: string) => {

            switch (message) {
              case "identity.session.endpoint_not_enabled":
                // addError({
                //   title: "Auth0 deshabilitado",
                //   message:
                //     "Actualmente los metodos de autenticación a traves de terceros estan deshabilitados",
                //   primaryButton: "CERRAR",
                // });
                break;
              case "authz.pending_authorization":
                navigate("/login/validate-session");
                break;
              case "identity.invalid_credentials":
                // addError({
                //   title: "Error con Auth0",
                //   message:
                //     "Los parametros enviados para la autenticacion a traves de Auth0 son inválidos",
                //   primaryButton: "CERRAR",
                // });
                navigate(signUpRedirect || "/signup/user/country", {
                  state: {
                    id_token: claims?.__raw,
                  },
                });
                break;
              case "identity.organization_login_not_allowed":
                navigate("/login/invalid-juridical-account", { replace: true });
                break;
              case "authz.rejected_session":
                navigate("/login/unauthorized");
                break;
              default:
                // addError();
                navigate("/login");
                break;
            }

          });

      try {
        setUser(undefined);
        // if you need the raw id_token, you can access it
        // using the __raw property
        const id_token = claims?.__raw;

        if (!id_token) {
          throw new DefaultError(["identity.session.auth0.invalid_claims"]);
        }

        const { data: flow } = await publicInstance.hodor.loginBrowserBegin({
          method: LoginMethods.OIDC,
          captcha_response: captcha_response,
        });

        const { data: session } = await publicInstance.hodor.loginFinish(
          flow.id,
          {
            id_token: id_token,
          },
        );

        if (session.csrf_token) {
          setCSRFToken(session.csrf_token);
        }

        const [{ data: user }, { data }] = await Promise.all([
          memberInstance.barong.getUser(),
          memberInstance.hodor.getMe(),
          memberInstance.hodor.getAccess(),
        ]);

        await onSuccess(user, data);
      } catch (error: unknown) {
        onError(error);
      }
    },
    [
      eventTracker,
      getIdTokenClaims,
      memberInstance.barong,
      memberInstance.hodor,
      navigate,
      onSuccessAuth,
      publicInstance.hodor,
      setCSRFToken,
    ],
  );

  const enterpriseLogin = useCallback(
    async (params: {
      redirectPath: string;
      captcha_response: string;
    }): Promise<void> => {
      const { captcha_response, redirectPath } = params;
      const identity_token = query.get("identity_token");
      const org_email = query.get("org_email");

      const onSuccess = async (
        user: UserWithFullInfo,
        userSessionInfo: UserWithSessionInfo,
      ): Promise<void> => {
        redirectPathRef.current = redirectPath || redirectPathRef.current;
        await onSuccessAuth(user, userSessionInfo);

        eventTracker.identify(user.uid, { email: user.email });
        eventTracker.capture(EVENT_TRACKER_EVENT_NAMES.USER_LOGGED_IN, {
          method: "Enterprise Login",
        });
      };

      const onError = onErrorHandler((message: string) => {
        switch (message) {
          case "identity.session.endpoint_not_enabled":
            // addError({
            //   title: "Auth0 deshabilitado",
            //   message:
            //     "Actualmente los metodos de autenticación a traves de terceros estan deshabilitados",
            //   primaryButton: "CERRAR",
            // });
            break;
          case "authz.pending_authorization":
            navigate("/login/validate-session");
            return;
          case "authz.rejected_session":
            navigate("/login/unauthorized");
            return;
          default:
            // addError();
            break;
        }

        navigate("/login");
      });

      try {
        setUser(undefined);

        const { data: flow } = await publicInstance.hodor.loginBrowserBegin({
          method: LoginMethods.INVITE,
          captcha_response: captcha_response,
        });

        const { data: session } = await publicInstance.hodor.loginFinish(
          flow.id,
          {
            org_email: org_email ?? "",
            identity_token: identity_token ?? "",
          },
        );

        if (session.csrf_token) {
          setCSRFToken(session.csrf_token);
        }

        const [{ data: user }, { data }] = await Promise.all([
          memberInstance.barong.getUser(),
          memberInstance.hodor.getMe(),
        ]);

        await onSuccess(user, data);
      } catch (error: unknown) {
        onError(error);
      }
    },
    [
      eventTracker,
      memberInstance.barong,
      memberInstance.hodor,
      navigate,
      onSuccessAuth,
      publicInstance.hodor,
      query,
      setCSRFToken,
    ],
  );

  const checkSession = useCallback(
    async (onFinished?: () => void) => {
      try {
        const [{ data: user }, { data }, { data: accounts }] =
          await Promise.all([
            memberInstance.barong.getUser(),
            memberInstance.hodor.getMe(),
            memberInstance.hodor.getAccess(),
          ]);

        await onSuccessAuth(
          user,
          data,
          accounts.length > 1 ? "/logged/select-account" : "/logged/dashboard",
        );
        onFinished?.();
      } catch (e) {
        if (!(errorParser(e) === "authz.pending_authorization")) {
          onFinished?.();
        }
        console.error(e);
      }
    },
    [memberInstance.barong, memberInstance.hodor, onSuccessAuth],
  );

  return (
    <UserContext.Provider
      value={{
        updateUserData,
        user,
        auth0LogIn,
        UTMParams,
        getUser,
        getUserWithoutRedirect,
        loading,
        logout,
        login,
        auth0isAuthenticated: isAuthenticated,
        checkSession,
        enterpriseLogin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
